import {numberOnlyRule} from '../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'paymentProviders',
		label: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.title'),
		type: 'select',
		placeholder: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.placeholder'),
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		selectProps: {
			options: [
				{
					label: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byCreditCard'),
					value: 2
				},
				{
					label: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byBalance'),
					value: 3
				}
			]
		}
	},
	{
		name: 'createdAt',
		label: t('pages.account.paymentHistoryPage.filter.byDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},

	{
		name: 'type',
		label: t('pages.account.paymentHistoryPage.filter.type.title'),
		type: 'select',
		placeholder: t('pages.account.paymentHistoryPage.filter.type.placeholder'),
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		selectProps: {
			options: [
				{
					label: t('pages.account.paymentHistoryPage.filter.type.options.ticketPurchase'),
					value: '100'
				},
				{
					label: t('pages.account.paymentHistoryPage.filter.type.options.chargeHolder'),
					value: '200'
				}
			]
		}
	},

	{
		name: 'amount',
		label: t('pages.account.partner.transactions.amount'),
		placeholder: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.placeholder'),
		type: 'text',
		style: {
			height: 50
		},
		rules: [numberOnlyRule(t)],
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'status',
		label: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'),
		placeholder: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'),
		type: 'select',
		style: {
			height: 50
		},
		dictionaryApiPath: 'payment-statuses',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	}
];
export default fields;
