import {saveAs} from 'file-saver';

export const fetchImage = async (url: string, setter: any) => {
	try {
		const response = await fetch(url, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		});

		if (response.ok) {
			const blob = await response.blob();
			const imageUrl = URL.createObjectURL(blob);
			setter(imageUrl);
			return blob;
		} else {
			console.error('Failed to fetch image:', response.status);
		}
	} catch (error) {
		console.error('Error fetching image:', error);
	}
};
export const downloadFile = (blob: Blob | undefined, filename: string) => {
	blob !== undefined && saveAs(blob, filename);
};
