import {Button, Form} from 'antd';
import {FormInstance} from 'antd/lib';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {validateMessages} from '../../../../../../Components/AuthModalForm';
import FormGroup from '../../../../../../Components/formItems/FormGroup';
import {getKeyByLang} from '../../../../../../utils/heplerFuncs/functions';
import {
	numberOnlyRule,
	numberRulesAmount,
	numberRulesAmountLittle,
	numberRulesMultiple
	// validateMultiple
} from '../../../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../../../utils/types';
const changeTariffFields: ModalFormFieldTypesFunc = (
	t,
	{oldTariff, oldTariffId, setSelectedTariffData, typeId, selectedTariffData}
) => {
	return [
		{
			name: 'oldTariff_nottoback',
			label: t('pages.account.ticketHoldersPage.actions.changeTariff.popup.oldTariff'),
			type: 'customElement',
			style: {
				height: 68
			},
			// inputProps: {
			// 	defaultValue: oldTariff
			// },
			component: () => {
				return (
					<div
						style={{
							float: 'left',
							display: 'flex',
							flexDirection: 'column',
							marginBottom: 9,
							alignItems: 'flex-start',
							width: '100%'
						}}>
						<div style={{float: 'left', marginBottom: 9}}>
							{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.oldTariff')}
						</div>
						<div
							style={{
								background: 'white',
								borderRadius: 6,
								border: '1px solid lightgray',
								height: 58,
								width: '100%',
								display: 'flex',
								alignItems: 'center'
							}}>
							<p style={{padding: '0 0 0 10px'}}>{oldTariff}</p>
						</div>
					</div>
				);
			},
			isRequired: false,
			colProps: {
				lg: 24,
				xs: 24
			}
		},
		{
			name: 'newTariffId',
			label: t('pages.account.ticketHoldersPage.actions.changeTariff.popup.selectNewTariff'),
			type: 'select',
			style: {
				height: 68
			},
			colProps: {
				lg: 24,
				xs: 24
			},
			hiddenOptions: [oldTariffId],
			onChange: (val: any) => {
				setSelectedTariffData(val);
			},
			customApiFetch: {
				path: 'api/v1/tariffs/public?holderTypeId=2',
				labelKey: getKeyByLang('name'),
				valueKey: val => {
					let tariffData = {
						id: val.id,
						price: val.price.slice(0, -3),
						step: val.topUpAmountStep,
						typeId: val?.typeId,
						topUpAmountMax: val?.topUpAmountMax,
						topUpAmountMin: val?.topUpAmountMin
					};
					return JSON.stringify(tariffData);
				}
			}
		},
		...(typeId === 2 && selectedTariffData !== '{}' && selectedTariffData !== undefined
			? [
					{
						label: t('pages.account.ticketHoldersPage.actions.changeTariff.popup.amount'),
						name: 'amount_num',
						type: 'text',
						style: {
							height: 68
						},
						rules: [
							numberOnlyRule(t),
							numberRulesAmountLittle(
								t,
								JSON.parse(selectedTariffData ?? '{}')?.topUpAmountMin
							),
							numberRulesAmount(t, JSON.parse(selectedTariffData ?? '{}')?.topUpAmountMax),
							numberRulesMultiple(t, JSON.parse(selectedTariffData ?? '{}')?.step)
						],
						colProps: {
							lg: 24,
							xs: 24
						}
					}
			  ]
			: [])
	];
};
const warningFields: ModalFormFieldTypesFunc = (t, {typeId, balance}) => [
	{
		name: 'customElement',
		type: 'customElement',
		colProps: {
			lg: 24,
			xs: 24
		},
		component: () =>
			typeId === 3 ? (
				<p>
					{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.cardWithMoney', {
						balance
					})}
				</p>
			) : (
				<p>{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.countCard', {balance})}</p>
			)
	}
];
export const ChangeTariffModal = ({
	onSubmitChangeTariff,
	changeTariffForm,
	formErrorsChangeTariff,
	oldTariff,
	holderNumber,
	loading,
	popupAction,
	oldTariffId,
	balance,
	typeId
}: {
	onSubmitChangeTariff: any;
	changeTariffForm: FormInstance<any>;
	formErrorsChangeTariff: string[];
	oldTariff: string;
	holderNumber: string;
	loading: boolean;
	popupAction: any;
	oldTariffId: string | number;
	balance: string;
	typeId: string | number;
}) => {
	let {t} = useTranslation();
	const [warningPopup, setWarningPopup] = useState(false);
	const [selectedTariffData, setSelectedTariffData] = useState('{}');
	const onSubmit = () => {
		const amountToCharge = changeTariffForm.getFieldValue('amount_num');
		let parsedData = JSON.parse(selectedTariffData);
		let sendData = {
			providerId: 2,
			amount: parsedData.typeId === 2 ? parsedData.price : amountToCharge,
			holderNumber: holderNumber,
			newTariffId: parsedData.id
		};
		changeTariffForm.validateFields().then(() => {
			onSubmitChangeTariff(sendData)
				.then((res: any) => changeTariffForm.resetFields())
				.catch((err: any) => console.log(err));
		});
	};

	const handleChangeTariff = () => {
		if (!!+balance && !warningPopup) {
			changeTariffForm
				.validateFields()
				.then(() => {
					setWarningPopup(true);
				})
				.catch(err => console.log);
		} else {
			changeTariffForm
				.validateFields()
				.then(() => {
					changeTariffForm.submit();
				})
				.catch(err => console.log);
		}
	};
	return (
		<>
			<Form
				form={changeTariffForm}
				layout="vertical"
				onFinish={onSubmit}
				validateTrigger="onBlur"
				validateMessages={validateMessages(t)}
				onValuesChange={() => {}}>
				<FormGroup
					tabFields={
						warningPopup
							? warningFields(t, {typeId, balance})
							: changeTariffFields(t, {
									oldTariff,
									oldTariffId,
									setSelectedTariffData,
									typeId,
									selectedTariffData
							  })
					}
					formErrors={formErrorsChangeTariff}
					form={changeTariffForm}
				/>
			</Form>
			<div className="main-buttons">
				<Button
					onClick={() => {
						changeTariffForm.resetFields();
						popupAction('changeTariff', false);
					}}>
					{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.cancelText')}
				</Button>
				<Button
					onClick={() => handleChangeTariff()}
					disabled={loading}
					type="primary"
					style={loading ? {background: 'gray', borderColor: 'gray'} : {}}>
					{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.okText')}
				</Button>
			</div>
		</>
	);
};
