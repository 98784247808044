import API from '../api';
import axiosClient from '../utils/axiosClient';

const getTickets = async () => {
	const res = await axiosClient.get(API.TICKETS.tickets);
	return res?.data;
};

const getMyTickets = async (data: any) => {
	const res = await axiosClient.get(API.TICKETS.myTickets, {
		params: {...data.filter, page: data.page, take: data.take, order: 'DESC'}
	});
	return res.data;
};

const buyTicketFromBalance = async (data: any) => {
	const res = await axiosClient.post(API.TICKETS.myTickets, data);
	return res.data;
};

const buyTicketWithPayment = async (data: any) => {
	const res = await axiosClient.post(API.PAYMENTS.paymentInit, data);
	return res.data;
};

const buyQRTicket = async (data: any) => {
	const res = await axiosClient.post(API.PAYMENTS.paymentInit + '/qr-ticket', data);
	return res.data;
};

const unauthorisedUserTicketsGet = async (id: any) => {
	const res = await axiosClient.get(`${API.PAYMENTS.paymentInitUnauth}/${id}/tickets`);
	return res?.data;
};

const TICKETS = {
	getTickets,
	getMyTickets,
	buyTicketFromBalance,
	buyTicketWithPayment,
	buyQRTicket,
	unauthorisedUserTicketsGet
};

export default TICKETS;
