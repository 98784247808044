export const rotateSvg = (
	<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group 39848">
			<path
				id="Vector"
				opacity="0.5"
				d="M23.5 46C35.9264 46 46 35.9264 46 23.5C46 11.0736 35.9264 1 23.5 1C11.0736 1 1 11.0736 1 23.5C1 35.9264 11.0736 46 23.5 46Z"
				stroke="#009E83"
				strokeWidth="2"
			/>
			<path
				id="Vector_2"
				d="M34.75 19H12.25L19.9844 12.25"
				stroke="#009E83"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				id="Vector_3"
				d="M12.25 28H34.75L27.0156 34.75"
				stroke="#009E83"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
);
